import React, { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from '../../components/ui/dialog';
import { RadioGroup, RadioGroupItem } from '../../components/ui/radio-group';
import { Label } from '../../components/ui/label';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '../../components/ui/alert-dialog';
import { Header } from '../../components/Header';
import useUserMembership from '../../hooks/useUserMembership';
import { useSearchParams } from 'react-router-dom';

const membershipStatusTextMap = {
  none: 'None',
  intro_plan: 'Intro Plan',
  standard_plan: 'Standard Plan',
  churned: 'Churned',
}

export default function Credits() {
  const { userMembership, isLoading, error } = useUserMembership();
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("sessionId");

  const [showBuyCreditsDialog, setShowBuyCreditsDialog] = useState(false);
  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false);
  const [showManagePlanDialog, setShowManagePlanDialog] = useState(false);
  const [selectedCredits, setSelectedCredits] = useState<string | null>(null);
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);

  const { creditBalance, membershipStatus, payingStatus } = userMembership || {};

  if (isLoading) {
    return (
      <div className="min-h-screen bg-[#F9F6F4]">
        <Header sessionId={sessionId} />
        <main className="max-w-4xl mx-auto flex items-center justify-center h-[60vh]">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900"></div>
        </main>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-[#F9F6F4]">
        <Header sessionId={sessionId} />
        <main className="max-w-4xl mx-auto p-4">
          <div className="text-red-600 text-center mt-8">
            {error}
          </div>
        </main>
      </div>
    );
  }

  const handleBuyCredits = () => {
    setShowBuyCreditsDialog(true)
  }

  const handleUpgrade = () => {
    setShowUpgradeDialog(true)
  }

  const handleManagePlan = () => {
    setShowManagePlanDialog(true)
  }

  const handleConfirmCredits = () => {
    if (selectedCredits) {
      const newCredits = parseInt(selectedCredits)
      // setCredits(prevCredits => prevCredits + newCredits)
    }
    setShowBuyCreditsDialog(false)
    setSelectedCredits(null)
  }

  const handleConfirmUpgrade = () => {
    if (selectedPlan === '149') {
      window.open('https://checkout.square.site/merchant/MLJGHSGB76H9G/checkout/P5TVZVYFMHFHBQF5VEYLNS5G', '_blank');
    } else if (selectedPlan === '1000') {
      window.open('https://checkout.square.site/merchant/MLJGHSGB76H9G/checkout/BVYYAYYJ3F7SVMSO2VNK4ZCM', '_blank');
    }
    setShowUpgradeDialog(false);
    setSelectedPlan(null);
  };

  const handlePausePlan = () => {
    // setPlanStatus('paused')
    setShowManagePlanDialog(false)
  }

  const handleCancelPlan = () => {
    // setPlanStatus('cancelled')
    setShowManagePlanDialog(false)
  }

  const handleRestartPlan = () => {
    // setPlanStatus('active')
  }

  const getMembershipStatusDisplay = () => {
    console.log(membershipStatus)
    let statusText = membershipStatusTextMap[membershipStatus || 'none']
    console.log(statusText)
    if (payingStatus === 'not_paying') {
      statusText += ' (Not Paying)'
    } else if (payingStatus === 'churned') {
      statusText += ' (Churned)'
    }
    return statusText
  }

  const getPayingStatusColor = () => {
    switch (payingStatus) {
      case 'not_paying':
        return 'text-yellow-600'
      case 'churned':
        return 'text-red-600'
      default:
        return 'text-black'
    }
  }

  return (
    <div className="min-h-screen bg-[#F9F6F4]">
      <Header sessionId={sessionId} />
      <main className="max-w-4xl mx-auto">
        <h1 className="text-xl md:text-2xl font-light mb-4 md:mb-8 mt-8 text-black tracking-wide" style={{ fontFamily: 'var(--font-montserrat)' }}>
          Credits and Membership
        </h1>
        
        {/* Credits Section */}
        <div className="bg-white rounded-lg overflow-hidden coastal-shadow p-6 md:p-8 mb-8 border border-taupe/20">
          <h2 className="text-xl font-light mb-4 text-black tracking-wide" style={{ fontFamily: 'var(--font-montserrat)' }}>Your Credits</h2>
          <p className="text-4xl font-light text-black mb-6 tracking-wide" style={{ fontFamily: 'var(--font-montserrat)' }}>{creditBalance}</p>
          <button 
            className="w-full md:w-auto text-base tracking-wide px-6 rounded-lg
              text-white h-10 luxury-button luxury-button-primary" 
            style={{ fontFamily: 'var(--font-montserrat)' }}
            onClick={() => setShowBuyCreditsDialog(true)}
          >
            Buy More Credits
          </button>
        </div>

        {/* Membership Plan Section */}
        <div className="bg-white rounded-lg overflow-hidden coastal-shadow p-6 md:p-8 border border-taupe/20">
          <h2 className="text-xl font-light mb-4 text-black tracking-wide" style={{ fontFamily: 'var(--font-montserrat)' }}>Membership Plan</h2>
          <p className={`text-lg mb-4 tracking-wide ${getPayingStatusColor()}`} style={{ fontFamily: 'var(--font-montserrat)' }}>
            Current Plan: {getMembershipStatusDisplay()}
          </p>
          <div className="flex flex-col md:flex-row gap-4">
            <button 
              className="w-full md:w-auto text-base tracking-wide px-6 rounded-lg
                h-9 luxury-button luxury-button-secondary" 
              style={{ fontFamily: 'var(--font-montserrat)' }}
              onClick={() => setShowUpgradeDialog(true)}
              disabled={payingStatus === 'churned' || membershipStatus === 'churned'}
            >
              {membershipStatus === 'churned' ? 'Already on Elite Plan' : 'Upgrade'}
            </button>
            <button 
              className="w-full md:w-auto text-base tracking-wide px-6 rounded-lg
                h-9 luxury-button luxury-button-secondary" 
              style={{ fontFamily: 'var(--font-montserrat)' }}
              onClick={() => setShowManagePlanDialog(true)}
              disabled={payingStatus === 'churned' || membershipStatus === 'churned'}
            >
              Manage Plan
            </button>
          </div>
          {payingStatus === 'churned' && (
            <>
              <p className="mt-4 text-sm text-red-600" style={{ fontFamily: 'var(--font-montserrat)' }}>
                Your plan is currently inactive. Restart your plan to continue enjoying our services.
              </p>
              <div className="mt-4">
                <button 
                  className="w-full md:w-auto text-base tracking-wide luxury-button luxury-button-primary" 
                  style={{ fontFamily: 'var(--font-montserrat)' }}
                  onClick={handleRestartPlan}
                >
                  Restart Plan
                </button>
                <p className="mt-2 text-sm text-green-600" style={{ fontFamily: 'var(--font-montserrat)' }}>
                  Click here to reactivate your membership.
                </p>
              </div>
            </>
          )}
        </div>

        {/* Buy Credits Dialog */}
        <Dialog open={showBuyCreditsDialog} onOpenChange={setShowBuyCreditsDialog}>
          <DialogContent className="sm:max-w-[425px] bg-white border-2 border-[#D8D4CC] relative">
            <button 
              onClick={() => setShowBuyCreditsDialog(false)}
              className="absolute right-4 top-4 text-gray-500 hover:text-gray-700 text-xl"
            >
              ×
            </button>
            <DialogHeader>
              <DialogTitle className="text-2xl font-light text-center mb-4 font-montserrat">
                Buy More Credits
              </DialogTitle>
              <DialogDescription className="text-center text-[#8A8784] font-montserrat">
                Select the number of credits you'd like to purchase.
              </DialogDescription>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <div className="space-y-4">
                {[
                  { value: "20", label: "20 Credits" },
                  { value: "50", label: "50 Credits" },
                  { value: "75", label: "75 Credits" },
                ].map((option) => (
                  <div 
                    key={option.value} 
                    className={`flex items-center p-4 rounded-lg border border-[#D8D4CC] cursor-pointer transition-all duration-200 ${
                      selectedCredits === option.value ? 'bg-gray-50 border-gray-400' : 'bg-white hover:bg-gray-50'
                    }`}
                    onClick={() => setSelectedCredits(option.value)}
                  >
                    <input
                      type="radio"
                      id={`r${option.value}`}
                      name="creditAmount"
                      value={option.value}
                      checked={selectedCredits === option.value}
                      onChange={(e) => setSelectedCredits(e.target.value)}
                      className="w-4 h-4 text-black border-gray-300 focus:ring-black"
                    />
                    <label htmlFor={`r${option.value}`} className="ml-2 text-lg font-montserrat w-full cursor-pointer">
                      {option.label}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <DialogFooter>
              <button 
                onClick={handleConfirmCredits} 
                disabled={!selectedCredits}
                className="w-full text-base tracking-wide rounded-lg text-white h-10 luxury-button luxury-button-primary font-montserrat disabled:opacity-50"
              >
                Confirm Purchase
              </button>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        {/* Upgrade Dialog */}
        <Dialog open={showUpgradeDialog} onOpenChange={setShowUpgradeDialog}>
          <DialogContent className="sm:max-w-[425px] bg-[#F8F7F4] border-2 border-[#D8D4CC]">
            <DialogHeader>
              <DialogTitle className="text-2xl font-light text-center mb-4 font-canela">
                Upgrade Your Plan
              </DialogTitle>
              <DialogDescription className="text-center text-[#8A8784] font-montserrat">
                Choose a plan that suits your needs.
              </DialogDescription>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <RadioGroup value={selectedPlan || ""} onValueChange={setSelectedPlan}>
                {[
                  { 
                    value: "149", 
                    name: "Premium Plan", 
                    price: "$149/month",
                    features: [
                      "7 introductions per month",
                      "24-48 hours for a new match"
                    ]
                  },
                  { 
                    value: "1000", 
                    name: "Elite Plan", 
                    price: "$1000/month",
                    features: [
                      "Invites to intimate dinners and wellness events",
                      "Bi-weekly check-ins with your matchmaker",
                      "Instant new matches",
                      "Unlimited matches"
                    ]
                  },
                ].map((plan) => (
                  <div key={plan.value} className="p-4 rounded-lg bg-white border border-[#D8D4CC]">
                    <div className="flex items-center justify-between mb-2">
                      <div className="flex items-center">
                        <RadioGroupItem value={plan.value} id={`p${plan.value}`} />
                        <Label htmlFor={`p${plan.value}`} className="ml-2 text-lg font-medium font-montserrat">
                          {plan.name}
                        </Label>
                      </div>
                      <span className="text-lg font-medium font-montserrat">{plan.price}</span>
                    </div>
                    <ul className="list-disc list-inside text-sm text-[#8A8784] ml-6">
                      {plan.features.map((feature, index) => (
                        <li key={index} className="font-montserrat">{feature}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </RadioGroup>
            </div>
            <DialogFooter>
              <button 
                onClick={handleConfirmUpgrade} 
                disabled={!selectedPlan}
                className="w-full text-base tracking-wide rounded-lg
                text-white h-9 luxury-button luxury-button-primary"
                style={{ fontFamily: 'var(--font-montserrat)' }}
              >
                Confirm Upgrade
              </button>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        {/* Manage Plan Dialog */}
        <AlertDialog open={showManagePlanDialog} onOpenChange={setShowManagePlanDialog}>
          <AlertDialogContent className="bg-[#F8F7F4] border-2 border-[#D8D4CC]">
            <AlertDialogHeader>
              <AlertDialogTitle className="text-2xl font-light text-center mb-4 font-canela">
                Manage Your Plan
              </AlertDialogTitle>
              <AlertDialogDescription className="text-center text-[#8A8784] font-montserrat">
                Would you like to pause or cancel your current plan?
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter className="flex flex-col sm:flex-row gap-2 justify-center items-center sm:px-6 font-montserrat">
              <AlertDialogCancel
                onClick={() => setShowManagePlanDialog(false)}
                className="w-full sm:w-auto order-1 sm:order-none luxury-button luxury-button-secondary text-sm rounded-lg"
              >
                Keep Current Plan
              </AlertDialogCancel>
              <AlertDialogAction className="w-full sm:w-auto luxury-button luxury-button-primary text-white text-sm rounded-lg" onClick={handlePausePlan}>
                Pause Plan
              </AlertDialogAction>
              <AlertDialogAction className="w-full sm:w-auto luxury-button luxury-button-primary text-white text-sm rounded-lg" onClick={handleCancelPlan}>
                Cancel Plan
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </main>
    </div>
  );
} 