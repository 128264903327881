import constants from "../constants";
import { Introduction } from "../core/models";

export const requestIntroductionService = async (matchId: string) => {
  try {
    const response = await fetch(
      `${constants.BACKEND_API_ENDPOINT}/matches/createIntroduction`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          match_id: matchId,
          request_by_user_directly: true,
        }),
      }
    );
    if (!response.ok) {
      const errorData = await response.json();
      if (errorData.detail) {
        console.error("Error details:", errorData.detail);
        throw new Error(errorData.detail);
      }
      throw new Error("Failed to request introduction");
    }
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const getMatches = async (sessionId: string) => {
  try {
    const response = await fetch(
      `${constants.BACKEND_API_ENDPOINT}/matches/get_matches?session_id=${sessionId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch matches");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching matches:", error);
    throw error;
  }
};

export const getMatch = async (matchId: string) => {
  try {
    const response = await fetch(
      `${constants.BACKEND_API_ENDPOINT}/matches/get_match?match_id=${matchId}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch match");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching match:", error);
    throw error;
  }
};

export const getAnotherMatch = async (matchId: string, feedback: string) => {
  try {
    const response = await fetch(
      `${constants.BACKEND_API_ENDPOINT}/matches/get_another_match`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ feedback, match_id: matchId }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to request another match");
    }

    return await response.json();
  } catch (error) {
    console.error("Error requesting another match:", error);
    throw error;
  }
};

export const getIntroductions = async (
  sessionId: string
): Promise<Introduction[]> => {
  try {
    const response = await fetch(
      `${constants.BACKEND_API_ENDPOINT}/matches/introductions?session_id=${sessionId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    // cast created_at and last_updated to Date
    const introductions = data.map((intro: any) => ({
      ...intro,
      created_at: new Date(intro.created_at),
      last_updated: new Date(intro.last_updated),
    }));
    return introductions;
  } catch (error) {
    console.error("Error fetching introductions:", error);
    if (error instanceof TypeError && error.message === "Failed to fetch") {
      console.warn(
        "This might be caused by a browser extension interfering with fetch requests."
      );
    }
    throw error;
  }
};


export const getIntroductionStatus = async (matchId: string) => {
  try {
    const response = await fetch(
      `${constants.BACKEND_API_ENDPOINT}/matches/get_introduction_status?match_id=${matchId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error getting introduction status:", error);
    if (error instanceof TypeError && error.message === "Failed to fetch") {
      console.warn(
        "This might be caused by a browser extension interfering with fetch requests."
      );
    }
    throw error;
  }
};