import { Link, useLocation, useNavigate } from 'react-router-dom'
import { User, CreditCard, Heart, ChevronLeft } from 'lucide-react'
import { FC, useEffect, useState } from 'react';
import Logo from './Logo';

type IProps = {
  sessionId?: string | null;
}

export const Header: FC<IProps> = ({ sessionId }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [canGoBack, setCanGoBack] = useState(false)
  const credits = null  // TODO: get credits from backend
  const isHomePage = location.pathname === '/'
  const pendingIntroductions = null  // TODO: get pending introductions from backend

  useEffect(() => {
    // Check if we can go back in history
    setCanGoBack(window.history.length > 1)
  }, [location])

  const handleBack = () => {
    navigate(-1)
  }

  return (
    <header className="sticky top-0 z-50 w-full border-b border-taupe/30 bg-white/95 backdrop-blur supports-[backdrop-filter]:bg-white/90 flex justify-center">
      <div className="container flex h-20 items-center justify-between px-4">
        <div className="flex-1">
          {!isHomePage && canGoBack && (
            <button
              onClick={handleBack}
              className="text-taupe hover:text-black transition-colors"
            >
              <ChevronLeft className="h-6 w-6" />
              <span className="sr-only">Go Back</span>
            </button>
          )}
        </div>
        <Link to="/">
          <Logo />
        </Link>
        <nav className="flex-1 flex items-center justify-end space-x-8">
          <Link 
            to={`/account/client-profile-new${sessionId ? `?sessionId=${sessionId}` : ''}`}
            className={`relative transition-colors ${location.pathname === '/account/client-profile-new' ? 'text-black' : 'text-black/70 hover:text-black'}`}
            aria-label="Client Profile"
          >
            <User className="h-6 w-6" />
          </Link>
          <Link 
            to={`/introductions${sessionId ? `?sessionId=${sessionId}` : ''}`}
            className={`relative transition-colors ${location.pathname === '/introductions' ? 'text-black' : 'text-black/70 hover:text-black'}`}
            aria-label="Introductions"
          >
            <Heart className="h-6 w-6" />
            {pendingIntroductions && (
              <span className="absolute -top-2 -right-2 flex h-5 w-5 items-center justify-center rounded-full bg-black text-[11px] font-medium text-white">
                {pendingIntroductions}
              </span>
            )}
          </Link>
          <Link 
            to={`/credits${sessionId ? `?sessionId=${sessionId}` : ''}`}
            className={`relative transition-colors ${location.pathname === '/credits' ? 'text-black' : 'text-black/70 hover:text-black'}`}
            aria-label="Credits"
          >
            <CreditCard className="h-6 w-6" />
            {credits && (
              <span className="absolute -top-2 -right-2 flex h-5 w-5 items-center justify-center rounded-full bg-black text-[11px] font-medium text-white">
                {credits}
              </span>
            )}
          </Link>
        </nav>
      </div>
    </header>
  )
}