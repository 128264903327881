import React, { FC, useState, useEffect } from "react";
import Div100vh from "react-div-100vh";
import { useMatches } from "../../hooks/useMatches";
import { usePostHog } from "posthog-js/react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Modal from "../../components/Modal";
import { Match } from "../../core/models";
import { Header } from "../../components/Header";


// Placeholder for loading state

const LoadingSkeleton: FC = () => (
  <Div100vh className="bg-[#f4f1ec] flex flex-col items-center justify-center !font-['Montserrat']">
    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-gray-700"></div>
    <p className="mt-4 text-lg font-light text-gray-600">Finding your match...</p>
  </Div100vh>
);

const MatchPage: FC = () => {
  const posthog = usePostHog();
  const [searchParams] = useSearchParams();
  const matchId = searchParams.get("matchId");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [isBottomBarTransparent, setIsBottomBarTransparent] = useState(false);
  const [showIntroRequestModal, setShowIntroRequestModal] = React.useState(false);
  const [showIntroRequestModalText, setShowIntroRequestModalText] = React.useState("Loading...");
  const [showRequestModal, setShowRequestModal] = React.useState(false);
  const [nextMatchFeedback, setNextMatchFeedback] = React.useState("");

  const {
    matches,
    targetGender,
    isLoading,
    sessionId,
    requestIntroduction,
    requestIntroError,
    requestAnotherMatchLoading,
    requestAnotherMatch,
    requestAnotherMatchSuccess,
  } = useMatches();

  const currentMatch = matches[0];

  console.log(currentMatch);

  const placeholderMatch: Match = {
    id: "match_123",
    name: "Sarah J.",
    age: 28,
    location: "San Francisco, CA",
    bio: "Love hiking, photography, and trying new restaurants",
    description: "Sarah is an outgoing software engineer who enjoys outdoor activities",
    fit_description: "You both share a passion for hiking and technology",
    prospect_image: "https://images.squarespace-cdn.com/content/v1/616754c4a06ef45b9f50e0db/1635103628149-KWX6DSQ8FB6I8SP6XF0F/Vanessa+girl+with+the+dogs-min.jpg",
    intro_tracking_link: "https://app.example.com/intros/match_123",
    personalized_matchmaker_note: "Sarah is a great match for you because she shares your passion for hiking and technology",
    client_is_paying: true,
  };

  const handleRequestIntroduction = async () => {
    if (!matchId) {
      console.error("No match ID available in URL");
      return;
    }

    setIsModalOpen(true);
    const success = await requestIntroduction(matchId);
    if (success) {
      posthog.capture("introduction_requested", {
        match_id: matchId,
      });
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    navigate(`/introductions?sessionId=${sessionId}`);
  };

  // Add toggle handler
  const toggleBottomBarTransparency = () => {
    setIsBottomBarTransparent(prev => !prev);
  };

  // Add useEffect to load Google Fonts
  React.useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap';
    document.head.appendChild(link);
  }, []);

  if (isLoading) return <LoadingSkeleton />;

  return (
<div className="min-h-screen bg-[#F9F6F4]">
      <Header sessionId={sessionId} />

      <main className="mx-auto px-4 py-8 bg-[#E8E6E1]">
        <div className="max-w-2xl bg-white rounded-lg shadow-lg mx-auto md:px-8 pt-4 pb-[6.5rem]" style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}>
          <div className="bg-white rounded-lg shadow-sm px-8 py-4 relative">
            <h2 style={{ fontFamily: 'Montserrat' }} className="md:text-2xl text-xl font-bold text-center mb-4">
              Meet {currentMatch ? currentMatch.name : placeholderMatch.name} — {targetGender === 'male' ? 'he' : 'she'} might be the one!
            </h2>
            <div className="max-w-xl mx-auto">

              {/* Image */}
              <div className="aspect-[3/4] bg-gray-200 mb-6 rounded-lg overflow-hidden max-w-sm mx-auto shadow-md">
                <img
                  src={currentMatch ? `data:image/jpeg;base64,${currentMatch.prospect_image}` : placeholderMatch.prospect_image}
                  alt={currentMatch ? currentMatch.name : placeholderMatch.name}
                  className="w-full h-full object-cover"
                  onError={(e) => (e.currentTarget.src = "/fallback-image.jpg")}
                />
              </div>

              {/* Match Details */}
              <div className="text-center">
                <p style={{ fontFamily: 'Montserrat' }} className="text-lg font-light text-gray-600 mb-3">
                  {currentMatch ? `${currentMatch.location} | ${currentMatch.age} years old` : `${placeholderMatch.location} | ${placeholderMatch.age} years old`}
                </p>
                <h4 style={{ fontFamily: 'Montserrat' }} className="text-lg md:text-xl font-semibold mb-2">
                  {currentMatch ? `${currentMatch.name.split(' ')[0]}'s story` : `${placeholderMatch.name.split(' ')[0]}'s story`}
                </h4>
                <p style={{ fontFamily: 'Montserrat' }} className="text-md md:text-md text-gray-600 mb-6 font-light">
                  {currentMatch ? currentMatch.bio : placeholderMatch.bio}
                </p>
              </div>

              {currentMatch && currentMatch.personalized_matchmaker_note && (
                <div>
                  <h4 style={{ fontFamily: 'Montserrat' }} className="font-serif text-lg mb-2 font-semibold">Why we selected this match</h4>
                  <p style={{ fontFamily: 'Montserrat' }} className="text-sm md:text-md text-gray-600 mb-6 font-light">
                    {currentMatch.personalized_matchmaker_note}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Action Buttons */}
        <div
          onClick={toggleBottomBarTransparency}
          className={`fixed bottom-0 left-0 right-0 px-4 pb-6 pt-8 transition-all duration-300 ${isBottomBarTransparent
              ? 'bg-transparent'
              : 'bg-gradient-to-t from-[#F9F6F4] via-[#F9F6F4]/80 to-transparent'
            }`}
          style={{ fontFamily: 'Montserrat' }}
        >
          <div className="max-w-xl mx-auto space-y-3">
            {currentMatch?.introduction_requested ? (
              <button
                disabled
                className={`w-full h-14 bg-gray-400 text-white font-medium rounded-lg ${
                  isBottomBarTransparent ? 'opacity-0' : 'opacity-100'
                }`}
                style={{ fontFamily: 'Montserrat' }}
              >
                Introduction Requested
              </button>
            ) : (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleRequestIntroduction();
                }}
                className={`w-full h-14 bg-black text-white font-medium rounded-lg hover:bg-gray-900 transition-all duration-300 ${
                  isBottomBarTransparent ? 'opacity-0 pointer-events-none' : 'opacity-100'
                }`}
                style={{ fontFamily: 'Montserrat' }}
              >
                Get Introduced (5 credits)
              </button>
            )}

            {requestIntroError && (
              <p className="text-red-500 text-sm font-normal text-center">{requestIntroError}</p>
            )}

            {!currentMatch?.introduction_requested && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setShowRequestModal(true);
                }}
                className={`w-full h-14 border border-black/10 bg-white text-black font-medium rounded-lg hover:bg-gray-50 transition-all duration-300 ${isBottomBarTransparent ? 'opacity-0 pointer-events-none' : 'opacity-100'
                  }`}
                style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px', fontFamily: 'Montserrat' }}
              >
                Request Another Match (2 credits)
              </button>
            )}
          </div>
        </div>
      </main>

      <Modal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        title="Introduction Requested"
      >
        <div className="text-center rounded-lg">
          <p className="text-gray-600" style={{ fontFamily: 'Montserrat' }}>
            Your matchmaker will reach out to see if there is mutual interest!
          </p>
        </div>
      </Modal>

      <Modal
        isOpen={showRequestModal}
        onClose={() => setShowRequestModal(false)}
        title="Request Another Match"
        onConfirm={() => {
          requestAnotherMatch(nextMatchFeedback);
          setNextMatchFeedback("");
          // posthog?.capture("request_another_match_clicked", { success: true });
        }}
        onConfirmText="Request Another Match (2 credits)">
        <div className="p-4 w-[300px] max-w-10/12">
          {requestAnotherMatchLoading ? (
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900 ml-auto mr-auto "></div>
          ) : requestAnotherMatchSuccess === null ? (
            <div className="flex flex-col space-y-4 text-left">
              <p>Feedback for your next match (optional)</p>
              <textarea
                className="w-full h-24 border border-gray-300 rounded-md p-2"
                value={nextMatchFeedback}
                onChange={(e) => setNextMatchFeedback(e.target.value)}
              />
            </div>
          ) : requestAnotherMatchSuccess ? (
            <p className="text-lg text-emerald-700">
              Your matchmaker has been alerted and is working on finding you
              another match!
            </p>
          ) : (
            <p className="text-lg text-red-600">
              Failed to request another match. Please try again later or
              contact support.
            </p>
          )}
        </div>
      </Modal>
    </div>
  )
};

export default MatchPage;