import constants from "../constants";
import { UserMembership } from "../types/user";

export const getClientBio = async (sessionId: string) => {
  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/user/client_bio?session_id=${sessionId}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const data = await response.json();
  return data;
};

export const updateClientBio = async (sessionId: string, newBio: string) => {
  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/user/update_bio?session_id=${sessionId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ bio: newBio }),
    }
  );
  const data = await response.json();
  return data.bio;
};

export const updateClientProfilePhoto = async (
  sessionId: string,
  file: File
) => {
  const formData = new FormData();
  formData.append("photo", file);

  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/user/update_profile_photo?session_id=${sessionId}`,
    {
      method: "POST",
      body: formData,
    }
  );
  const data = await response.json();
  return data;
};

export const getUserMembership = async (sessionId: string): Promise<UserMembership> => {
  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/user/membership?session_id=${sessionId}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const data = await response.json();
  return {
    creditBalance: data.credit_balance,
    membershipStatus: data.membership_status,
    payingStatus: data.paying_status
  };
};

interface ProfileData {
  user_photos_urls?: string[];
  bio?: string;
  email?: string;
  dob_month?: string;
  dob_day?: string;
  dob_year?: string;
  gender?: string;
  attracted_to?: string;
  religion?: string;
  current_location?: string;
  signup_location?: string;
  profession?: string;
  company?: string;
  linkedin?: string;
  must_have_attributes?: string[];
  nice_to_have_attributes?: string[];
}

export const getClientProfile = async (sessionId: string): Promise<ProfileData> => {
  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/user/get_profile?session_id=${sessionId}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const responseText = await response.text();
  
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  try {
    return JSON.parse(responseText);
  } catch (parseError) {
    console.error('JSON Parse Error:', parseError);
    throw new Error('Invalid JSON response from server');
  }
};
