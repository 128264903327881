import React from 'react'

interface AlertDialogProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  children: React.ReactNode
}

interface AlertDialogContentProps {
  className?: string
  children: React.ReactNode
}

const AlertDialog: React.FC<AlertDialogProps> = ({ open, onOpenChange, children }) => {
  if (!open) return null

  return (
    <div className="fixed inset-0 z-50 bg-black/50" onClick={() => onOpenChange(false)}>
      {children}
    </div>
  )
}

const AlertDialogContent: React.FC<AlertDialogContentProps> = ({ className = "", children }) => {
  return (
    <div 
      className={`fixed left-[50%] top-[50%] z-50 grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 border bg-white p-6 shadow-lg sm:rounded-lg ${className}`}
      onClick={e => e.stopPropagation()}
    >
      {children}
    </div>
  )
}

const AlertDialogHeader: React.FC<AlertDialogContentProps> = ({ className = "", children }) => (
  <div className={`flex flex-col space-y-2 text-center sm:text-left ${className}`}>
    {children}
  </div>
)

const AlertDialogFooter: React.FC<AlertDialogContentProps> = ({ className = "", children }) => (
  <div className={`flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2 ${className}`}>
    {children}
  </div>
)

const AlertDialogTitle: React.FC<AlertDialogContentProps> = ({ className = "", children }) => (
  <h2 className={`text-lg font-semibold ${className}`}>
    {children}
  </h2>
)

const AlertDialogDescription: React.FC<AlertDialogContentProps> = ({ className = "", children }) => (
  <p className={`text-sm text-gray-500 ${className}`}>
    {children}
  </p>
)

const AlertDialogAction: React.FC<AlertDialogContentProps & { onClick?: () => void }> = ({ className = "", onClick, children }) => (
  <button
    className={`inline-flex items-center justify-center px-4 py-2 font-medium transition-colors bg-primary text-primary-foreground hover:bg-primary/90 ${className}`}
    onClick={onClick}
  >
    {children}
  </button>
)

const AlertDialogCancel: React.FC<AlertDialogContentProps & { onClick?: () => void }> = ({ className = "", onClick, children }) => (
  <button
    className={`inline-flex items-center justify-center px-4 py-2 font-medium transition-colors border border-input bg-background hover:bg-accent hover:text-accent-foreground ${className}`}
    onClick={onClick}
  >
    {children}
  </button>
)

export {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogAction,
  AlertDialogCancel,
} 