import React, { FC } from "react";
import Div100vh from "react-div-100vh";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import { useMatches } from "../../hooks/useMatches";
import BillingSheet from "./billing-sheet";
import { Sheet } from "react-modal-sheet";
import fPreview from "../../assets/media/f-preview.png";
import mPreview from "../../assets/media/m-preview.png";
import { usePostHog } from "posthog-js/react";

const MatchesPage: FC = () => {
  const posthog = usePostHog();
  const [showBilling, setShowBilling] = React.useState(false);
  const { matches, isLoading, error, sessionId, targetGender } = useMatches();
  const [currentProfileIndex, setCurrentProfileIndex] = React.useState(0);

  React.useEffect(() => {
    posthog.capture("matches_page_opened");
  }, [posthog]);

  if (isLoading) {
    return (
      <Div100vh className="bg-[#f4f1ec] flex flex-col items-center justify-center">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
        <p className="mt-4 text-xl font-lato">Loading matches...</p>
      </Div100vh>
    );
  }

  if (error) {
    return (
      <Div100vh className="bg-[#f4f1ec] flex flex-col items-center justify-center">
        <p className="text-xl  text-red-600">{error}</p>
        <p className="text-xl font-lato text-center p4">
          Please contact support at
          <br />
          <a href="tel:+12122875646" className="text-blue-500">
            +1 (212)-287-5646
          </a>
          <br />
          or email
          <br />
          <a href="mailto:support@curate.date" className="text-blue-500">
            support@curate.date
          </a>
        </p>
      </Div100vh>
    );
  }

  const currentProfile = matches[currentProfileIndex];

  return (
    <div className="flex flex-col items-center max-w-screen w-full">
      <div
        className={`bg-[#f4f1ec] flex flex-col items-center w-screen min-h-screen max-w-[400px]`}
      >
        {/* Header */}
        <div className="flex flex-row w-full bg-[#f4f1ec] justify-between items-center p-4">
          <a
            href="/"
            className="text-2xl font-bold font-playfair text-gray-400 z-25"
          >
            Curate
          </a>
          <h3 className="text-xl font-semibold font-playfair italic">
            Matches
          </h3>
          <a
            href="mailto:support@curate.date"
            className="text-md font-lato text-gray-400 underline"
          >
            support
          </a>
        </div>

        {/* Match Profile */}
        <section className="flex flex-col items-center px-4 space-y-4 mb-[80px]">
          {currentProfile ? (
            <div className="w-full items-center flex flex-col border-[.5px] border-slate-600 rounded-md shadow-sm">
              <img
                src={`data:image/jpeg;base64,${currentProfile.prospect_image}`}
                className="w-full rounded-t-md object-cover max-h-[400px]"
                alt={currentProfile.name}
              />
              <div className="py-2 px-4 font-lato">
                <span>
                  <h4 className="text-center font-semibold text-lg text-slate-500 inline">
                    {currentProfile.name}
                  </h4>
                  <p className="text-slate-500 inline">
                    {" "}
                    | {currentProfile.age} years old | {currentProfile.location}
                  </p>
                </span>
                <p>{currentProfile.bio || currentProfile.description}</p>
                <p>{currentProfile.fit_description}</p>
              </div>
            </div>
          ) : (
            <div className="w-full items-center flex flex-col border-[.5px] border-slate-600 rounded-md shadow-sm p-4  py-6 *:space-y-2 font-lato">
              {targetGender === "female" ? (
                <img src={fPreview} alt="Female preview" className="w-11/12 ml-4" />
              ) : (
                <img src={mPreview} alt="Male preview" className="w-11/12 ml-4" />
              )}
              <p className="font-semibold text-lg">See more profiles...</p>
              <p className="text-center">
                Join Curate to see more profiles, get introduced, and discover
                your match amongst thousands of vetted members.
                <br />
                <br />
                We'll find you highly relevant people each week based on your
                specific preferences, and can reach out to them to make curated,
                high-touch introductions to you.
              </p>
              <br />
              <p className="font-semibold text-lg">Have Questions? </p>
              <p>Text our team</p>
              <a href="sms:650-223-5841" className="text-blue-500">
                +(650) 223-5841
              </a>{" "}
            </div>
          )}

          {/* Navigation Buttons */}
          {currentProfileIndex > 0 && (
            <div
              className="absolute -left-4 top-1/3 transform -translate-y-1/2 bg-slate-50 bg-opacity-60 rounded-lg flex flex-col items-center justify-center w-18 h-20 z-40 shadow-sm cursor-pointer"
              onClick={() =>
                setCurrentProfileIndex((prevIndex) => prevIndex - 1)
              }
            >
              <FaChevronLeft className="h-20 w-8 text-gray-600 rounded-full pt-4 px-1" />
              <p className="text-gray-600 text-sm px-5 py-2">
                {currentProfileIndex} of {matches.length}
              </p>
            </div>
          )}
          {currentProfileIndex < matches.length && (
            <div
              className="absolute right-0 top-1/3 transform -translate-y-1/2 bg-slate-50 bg-opacity-60 rounded-l-lg flex flex-col items-center justify-center w-18 h-20 z-40 shadow-sm cursor-pointer "
              onClick={() =>
                setCurrentProfileIndex((prevIndex) => prevIndex + 1)
              }
            >
              <FaChevronRight className="h-20 w-8 text-gray-600 rounded-full pt-4 pl-3" />
              <p className="text-gray-600 text-sm pl-5 pr-2 py-2">
                {currentProfileIndex + 1} of {matches.length + 1}
              </p>
            </div>
          )}
        </section>

        {!showBilling && (
          <section>
            <div
              className="rounded-full border-[#bd9e36] bg-[#c49f2a] fixed bottom-2 left-2 right-2 mx-4 h-[75px] flex flex-col items-center justify-center p-4 shadow-lg animate-gentle-bounce z-50"
              onClick={() => {
                setShowBilling(true);
                posthog.capture("billing_button_clicked");
              }}
            >
              {currentProfile ? (
                <p className="text-white font-semibold text-xl font-lato">
                  Get Introduced
                </p>
              ) : (
                <p className="text-white font-semibold text-xl font-lato">
                  Join Curate for More Matches
                </p>
              )}
            </div>
          </section>
        )}
      </div>
      <Sheet isOpen={showBilling} onClose={() => setShowBilling(false)}>
        <Sheet.Container>
          <Sheet.Header className="bg-[#f4f1ec] pb-4" />
          <Sheet.Scroller>
            <BillingSheet
              onClose={() => setShowBilling(false)}
              sessionId={sessionId}
            />
          </Sheet.Scroller>
        </Sheet.Container>
      </Sheet>
    </div>
  );
};

export default MatchesPage;
