const Logo = () => (
    <svg width="166" height="32" viewBox="0 0 166 34" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-6">
        <g clipPath="url(#clip0_948_15)">
        <g clipPath="url(#clip1_948_15)">
            <path d="M4.03649 17.471C4.03649 26.5053 7.40551 28.8748 11.9593 28.8748C15.6622 28.8748 18.8094 26.7272 19.363 22.6925H20.0674C19.4384 27.3196 16.1426 29.9497 11.8107 29.9497C4.85074 29.9476 0.816101 24.7282 0.816101 17.6562C0.816101 10.5843 4.7775 4.77253 11.4423 4.77253C15.8475 4.77253 19.2165 7.40054 19.8843 12.0297H19.1432C18.5509 7.99507 15.3305 5.84742 11.5909 5.84742C7.66616 5.84527 4.03649 7.99292 4.03649 17.471Z" fill="black"/>
            <path d="M50.0932 5.14302H51.0927V20.8788C51.0927 27.0244 47.3531 29.9497 42.319 29.9497C36.6537 29.9497 32.9896 27.1731 32.9896 20.323V5.14302H35.9881V20.323C35.9881 26.4687 38.9134 28.6529 42.7627 28.6529C46.3903 28.6529 50.0932 26.5807 50.0932 21.2105V5.14302Z" fill="black"/>
            <path d="M72.5671 18.0634H69.3467V29.5771H66.3116V5.14302H73.0863C78.2324 5.14302 81.3429 7.40053 81.3429 11.3619C81.3429 14.4725 79.1953 17.0272 75.5678 17.8049L83.8245 29.5792H80.2702L72.5693 18.0655L72.5671 18.0634ZM69.3467 6.29116V16.954H74.1224C76.1214 16.954 78.1204 15.7326 78.1204 11.5493C78.1204 7.36606 75.8995 6.29332 73.0863 6.29332H69.3467V6.29116Z" fill="black"/>
            <path d="M107.851 21.6176H97.8175L94.6337 29.577H93.4124L103.298 4.95776H104.148L114.368 29.577H111.147L107.851 21.6176ZM102.742 9.25305L98.2613 20.5083H107.405L102.74 9.25305H102.742Z" fill="black"/>
            <path d="M131.62 6.29116V29.5792H128.585V6.29116H120.365V5.14302H139.801V6.29116H131.62Z" fill="black"/>
            <path d="M152.316 5.14302H165.793V6.29116H155.352V16.6955H165.052V17.807H155.352V28.4332H166.273V29.5814H152.314V5.14302H152.316Z" fill="black"/>
        </g>
        </g>
        <defs>
        <clipPath id="clip0_948_15">
            <rect width="165.261" height="33.465" fill="white" transform="translate(0.724915 0.444988)"/>
        </clipPath>
        <clipPath id="clip1_948_15">
            <rect width="165.459" height="25.175" fill="white" transform="translate(0.816101 4.77253)"/>
        </clipPath>
        </defs>
    </svg>
);

export default Logo; 