import React from 'react'

interface RadioGroupProps {
  value: string
  onValueChange: (value: string) => void
  children: React.ReactNode
  className?: string
}

interface RadioGroupItemProps {
  value: string
  id: string
  className?: string
}

interface RadioGroupContextType {
  value: string;
  onValueChange: (value: string) => void;
}

const RadioGroupContext = React.createContext<RadioGroupContextType | undefined>(undefined);

const RadioGroup: React.FC<RadioGroupProps> = ({ value, onValueChange, children, className = "" }) => {
  return (
    <RadioGroupContext.Provider value={{ value, onValueChange }}>
      <div className={`space-y-2 ${className}`}>
        {children}
      </div>
    </RadioGroupContext.Provider>
  )
}

const RadioGroupItem: React.FC<RadioGroupItemProps> = ({ value, id, className = "" }) => {
  const context = React.useContext(RadioGroupContext);
  if (!context) {
    throw new Error('RadioGroupItem must be used within a RadioGroup');
  }

  return (
    <input
      type="radio"
      id={id}
      value={value}
      checked={context.value === value}
      onChange={(e) => context.onValueChange(e.target.value)}
      className={`w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 ${className}`}
    />
  )
}

export { RadioGroup, RadioGroupItem } 