import React, { FC, useEffect, useState } from "react";
import useUser from "../../hooks/useUser";
import { useSearchParams } from "react-router-dom";
import { Header } from "../../components/Header";
import { XMarkIcon, PlusIcon, PencilIcon } from "@heroicons/react/24/outline";
import { getClientProfile } from "../../services/userService";

// Add these interfaces near the top of the file, after the imports
interface ProfileData {
  photos: Array<{ id: string; url: string }>;
  bio: string;
  email: string;
  dateOfBirth: {
    month: string;
    day: string;
    year: string;
  };
  gender: string;
  attractedTo: string;
  religion: string;
  currentLocation: string;
  hometown: string;
  profession: string;
  companyName: string;
  linkedinProfile: string;
  preferences: Array<{
    id: string;
    text: string;
    isMustHave: boolean;
  }>;
}

const ClientProfilePage: FC = () => {
  const { userBio, userName, updateBio, updateProfilePhoto, profilePhotoUrl } =
    useUser();
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("sessionId");
  console.log(sessionId);

  const [editableBio, setEditableBio] = useState(userBio);
  const [isEditing, setIsEditing] = useState(false);

  // New state for preferences
  type Preference = {
    id: string;
    text: string;
    isMustHave: boolean;
  };

  const [preferences, setPreferences] = useState<Preference[]>([
    { id: '1', text: 'Kind and ambitious', isMustHave: true }
  ]);
  const [newPreference, setNewPreference] = useState('');
  const [preferenceType, setPreferenceType] = useState<'must-have' | 'nice-to-have' | ''>('');
  const [editingPreference, setEditingPreference] = useState<{
    id: string;
    type: 'must-have' | 'nice-to-have';
  } | null>(null);
  const [editingId, setEditingId] = useState<string | null>(null);

  const [photos, setPhotos] = useState([
    { id: '1', url: 'https://images.squarespace-cdn.com/content/v1/616754c4a06ef45b9f50e0db/1635103628149-KWX6DSQ8FB6I8SP6XF0F/Vanessa+girl+with+the+dogs-min.jpg' },
    { id: '2', url: '' },
    { id: '3', url: '' }
  ]);

  // Add these new state variables
  const [email, setEmail] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState({ month: '', day: '', year: '' });
  const [gender, setGender] = useState('');
  const [attractedTo, setAttractedTo] = useState('');
  const [religion, setReligion] = useState('');
  const [currentLocation, setCurrentLocation] = useState('');
  const [hometown, setHometown] = useState('');
  const [profession, setProfession] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [linkedinProfile, setLinkedinProfile] = useState('');

  // Add loading and error states
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Update the useEffect to use the new service
  useEffect(() => {
    const fetchProfileData = async () => {
      if (!sessionId) {
        setError('No session ID found');
        setIsLoading(false);
        return;
      }

      try {
        const data = await getClientProfile(sessionId);

        console.log(data);
        
        // Update state with fetched data
        setPhotos(data.user_photos_urls?.map((url: string, index: number) => ({
          id: String(index + 1),
          url
        })) || []);
        setEditableBio(data.bio || '');
        setEmail(data.email || '');
        setDateOfBirth({
          month: data.dob_month || '',
          day: data.dob_day || '',
          year: data.dob_year || ''
        });
        setGender(data.gender || '');
        setAttractedTo(data.attracted_to || '');
        setReligion(data.religion || '');
        setCurrentLocation(data.current_location || '');
        setHometown(data.signup_location || '');
        setProfession(data.profession || '');
        setCompanyName(data.company || '');
        setLinkedinProfile(data.linkedin || '');
        
        // Convert must_have and nice_to_have attributes to preferences format
        const combinedPreferences = [
          ...(data.must_have_attributes || []).map((text: string) => ({
            id: crypto.randomUUID(),
            text,
            isMustHave: true
          })),
          ...(data.nice_to_have_attributes || []).map((text: string) => ({
            id: crypto.randomUUID(),
            text,
            isMustHave: false
          }))
        ];
        setPreferences(combinedPreferences);

      } catch (error) {
        console.error('Error details:', error);
        setError(error instanceof Error ? error.message : 'Failed to load profile data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchProfileData();
  }, [sessionId]);

  const handleBioChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditableBio(e.target.value);
  };

  useEffect(() => {
    setEditableBio(userBio);
  }, [userBio]);

  const handleSaveBio = async () => {
    if (sessionId && editableBio) {
      await updateBio(sessionId, editableBio);
      setIsEditing(false);
    }
  };

  const handlePhotoUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && sessionId) {
      await updateProfilePhoto(sessionId, file);
    }
  };

  // Handlers for preferences
  const handleAddPreference = () => {
    if (newPreference.trim() && preferenceType) {
      setPreferences(prev => [...prev, {
        id: Date.now().toString(),
        text: newPreference.trim(),
        isMustHave: preferenceType === 'must-have'
      }]);
      setNewPreference('');
      setPreferenceType(''); // Reset radio selection
    }
  };

  const handleDeletePreference = (id: string) => {
    setPreferences(prev => prev.filter(pref => pref.id !== id));
    if (editingId === id) {
      setEditingId(null);
    }
  };

  const handleEditClick = (preference: Preference) => {
    if (editingId === preference.id) {
      setEditingId(null);
      setEditingPreference(null);
    } else {
      setEditingId(preference.id);
      setNewPreference(preference.text);
      setEditingPreference({
        id: preference.id,
        type: preference.isMustHave ? 'must-have' : 'nice-to-have'
      });
    }
  };

  const handleUpdatePreference = (id: string) => {
    if (editingPreference?.type) {
      setPreferences(prev => prev.map(pref =>
        pref.id === id
          ? { ...pref, text: newPreference, isMustHave: editingPreference.type === 'must-have' }
          : pref
      ));
      setEditingId(null);
      setEditingPreference(null);
      setNewPreference('');
    }
  };

  const handleDeletePhoto = (photoId: string) => {
    setPhotos(prev => prev.map(photo =>
      photo.id === photoId
        ? { ...photo, url: '' } // Clear the URL to show upload placeholder
        : photo
    ));
  };

  const handleSaveProfile = async () => {
    if (!sessionId) {
      console.error('No session ID found');
      return;
    }

    try {
      // First update the main profile data
      const response = await fetch('/api/update_profile', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          bio: editableBio,
          dob_month: dateOfBirth.month,
          dob_day: dateOfBirth.day,
          dob_year: dateOfBirth.year,
          gender,
          attracted_to: attractedTo,
          religion,
          current_location: currentLocation,
          hometown,
          profession,
          company_name: companyName,
          linkedin_profile: linkedinProfile
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update profile');
      }

      // Then update preferences
      // Remove all existing preferences and add new ones
      const mustHavePrefs = preferences.filter(p => p.isMustHave).map(p => p.text);
      const niceToHavePrefs = preferences.filter(p => !p.isMustHave).map(p => p.text);

      // You'll need to implement these API endpoints
      await Promise.all([
        ...mustHavePrefs.map(text => 
          fetch('/api/add_preference', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ text, is_must_have: true })
          })
        ),
        ...niceToHavePrefs.map(text => 
          fetch('/api/add_preference', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ text, is_must_have: false })
          })
        )
      ]);

      alert('Profile updated successfully!');
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Failed to update profile. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-[#F9F6F4]">
      <Header sessionId={sessionId} />
      <div className="max-w-4xl mx-auto pb-24">
        <h1 className="text-xl md:text-2xl font-light mb-4 md:mb-8 mt-8 text-black tracking-wide"
        style={{ fontFamily: 'Montserrat, sans-serif' }}>
          Your profile
        </h1>

        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-black"></div>
          </div>
        ) : error ? (
          // Commenting out the error message temporarily
          // <div className="bg-red-50 text-red-500 p-4 rounded-lg">
          //   {error}
          // </div>
          <div className="bg-white rounded-lg shadow p-6">
            <section className="mb-8">
              <h2 className="text-lg font-medium mb-4" style={{ fontFamily: 'var(--font-montserrat)' }}>Photos</h2>
              <div className="grid grid-cols-3 gap-4">
                {photos.map(photo => (
                  photo.url ? (
                    <div key={photo.id} className="relative aspect-[4/3] bg-gray-100 rounded-lg flex items-center justify-center overflow-hidden">
                      <img
                        src={photo.url}
                        alt="Profile"
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute top-2 right-2">
                        <button
                          className="p-0.5 sm:p-1 bg-red-500 rounded-full text-white"
                          onClick={() => handleDeletePhoto(photo.id)}
                        >
                          <XMarkIcon className="w-3 h-3 sm:w-4 sm:h-4" />
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div
                      key={photo.id}
                      className="aspect-[4/3] bg-[#F9F6F4] rounded-lg border-2 border-dashed border-[#C5C0B8] flex items-center justify-center cursor-pointer hover:bg-[#E8E6E4]"
                      onClick={() => document.getElementById('imageUpload')?.click()}
                    >
                      <input
                        type="file"
                        id="imageUpload"
                        className="hidden"
                        accept="image/*"
                        onChange={(e) => {
                          if (e.target.files && e.target.files[0]) {
                            const file = e.target.files[0];
                            // Handle the file upload here
                            e.target.value = '';
                          }
                        }}
                      />
                      <PlusIcon className="w-8 h-8 text-[#9F8E7D]" />
                    </div>
                  )
                ))}
              </div>
            </section>

            <section className="mb-8">
              <h2 className="text-lg font-medium mb-4" style={{ fontFamily: 'var(--font-montserrat)' }}>Bio</h2>
              <div className="relative">
                <textarea
                  className="w-full p-3 border rounded-lg resize-none pr-10 focus:border-[#9F8E7D] focus:ring-[#9F8E7D] focus:ring-1 focus:outline-none"
                  style={{ fontFamily: 'var(--font-montserrat)' }}
                  rows={4}
                  maxLength={225}
                  placeholder="Tell us about yourself..."
                />
                <div className="absolute bottom-3 right-3">
                  <button className="p-1 text-[#9F8E7D] hover:text-[#635A4F] bg-white">
                    <PencilIcon className="w-4 h-4" />
                  </button>
                </div>
                <div className="text-sm text-[#8A7B6C] mt-1" style={{ fontFamily: 'var(--font-montserrat)' }}>
                  Character limit: 225
                </div>
              </div>
            </section>

            <section>
              <h2 className="text-lg font-medium mb-4" style={{ fontFamily: 'var(--font-montserrat)' }}>Personal Information</h2>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-black mb-1" style={{ fontFamily: 'var(--font-montserrat)' }}>
                    Email
                  </label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full p-2 border rounded-lg focus:border-[#9F8E7D] focus:ring-[#9F8E7D] focus:ring-1 focus:outline-none"
                    placeholder="your@email.com"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-black mb-1" style={{ fontFamily: 'var(--font-montserrat)' }}>
                    Date of Birth
                  </label>
                  <div className="grid grid-cols-3 gap-4">
                    <select className="w-full p-2 border rounded-lg [&>option]:bg-[#D8D4CC] focus:border-[#9F8E7D] focus:ring-[#9F8E7D] focus:ring-1 focus:outline-none"
                      style={{ fontFamily: 'var(--font-montserrat)' }}>
                      <option value="" disabled selected>Month</option>
                      {[
                        "January", "February", "March", "April", "May", "June",
                        "July", "August", "September", "October", "November", "December"
                      ].map((month, index) => (
                        <option key={month} value={index + 1}>
                          {month}
                        </option>
                      ))}
                    </select>
                    <select className="w-full p-2 border rounded-lg [&>option]:bg-[#D8D4CC] focus:border-[#9F8E7D] focus:ring-[#9F8E7D] focus:ring-1 focus:outline-none" style={{ fontFamily: 'var(--font-montserrat)' }}>
                      <option value="" disabled selected>Day</option>
                      {[...Array(31)].map((_, i) => (
                        <option key={i + 1} value={i + 1}>
                          {i + 1}
                        </option>
                      ))}
                    </select>
                    <select className="w-full p-2 border rounded-lg [&>option]:bg-[#D8D4CC] focus:border-[#9F8E7D] focus:ring-[#9F8E7D] focus:ring-1 focus:outline-none" style={{ fontFamily: 'var(--font-montserrat)' }}>
                      <option value="" disabled selected>Year</option>
                      {[...Array((new Date()).getFullYear() - 1939)].map((_, i) => {
                        const year = (new Date()).getFullYear() - i;
                        return (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-black mb-1" style={{ fontFamily: 'var(--font-montserrat)' }}>
                    Gender
                  </label>
                  <select className="w-full p-2 border rounded-lg [&>option]:bg-[#D8D4CC] focus:border-[#9F8E7D] focus:ring-[#9F8E7D] focus:ring-1 focus:outline-none" style={{ fontFamily: 'var(--font-montserrat)' }}>
                    <option value="" disabled selected>Select gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="non-binary">Non-binary</option>
                    <option value="other">Other</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-black mb-1" style={{ fontFamily: 'var(--font-montserrat)' }}>
                    Attracted To
                  </label>
                  <select 
                    value={attractedTo}
                    onChange={(e) => setAttractedTo(e.target.value)}
                    className="w-full p-2 border rounded-lg [&>option]:bg-[#D8D4CC] focus:border-[#9F8E7D] focus:ring-[#9F8E7D] focus:ring-1 focus:outline-none" 
                    style={{ fontFamily: 'var(--font-montserrat)' }}
                  >
                    <option value="" disabled>Select gender(s) you're attracted to</option>
                    <option value="men">Men</option>
                    <option value="women">Women</option>
                    <option value="non-binary">Non-binary</option>
                    <option value="all">All Genders</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-black mb-1" style={{ fontFamily: 'var(--font-montserrat)' }}>
                    Religion
                  </label>
                  <select className="w-full p-2 border rounded-lg [&>option]:bg-[#D8D4CC] focus:border-[#9F8E7D] focus:ring-[#9F8E7D] focus:ring-1 focus:outline-none" style={{ fontFamily: 'var(--font-montserrat)' }}>
                    <option value="" disabled selected>Select your religion</option>
                    <option value="christianity">Christianity</option>
                    <option value="islam">Islam</option>
                    <option value="hinduism">Hinduism</option>
                    <option value="buddhism">Buddhism</option>
                    <option value="judaism">Judaism</option>
                    <option value="sikhism">Sikhism</option>
                    <option value="atheism">Atheism</option>
                    <option value="agnosticism">Agnosticism</option>
                    <option value="other">Other</option>
                    <option value="prefer-not-to-say">Prefer not to say</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-black mb-1" style={{ fontFamily: 'var(--font-montserrat)' }}>
                    Current Location
                  </label>
                  <input
                    type="text"
                    className="w-full p-2 border rounded-lg focus:border-[#9F8E7D] focus:ring-[#9F8E7D] focus:ring-1 focus:outline-none"
                    style={{ fontFamily: 'var(--font-montserrat)' }}
                    placeholder="e.g., New York, NY"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-black mb-1" style={{ fontFamily: 'var(--font-montserrat)' }}>
                    Hometown
                  </label>
                  <input
                    type="text"
                    className="w-full p-2 border rounded-lg"
                    style={{ fontFamily: 'var(--font-montserrat)' }}
                    placeholder="e.g., Chicago, IL"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-black mb-1" style={{ fontFamily: 'var(--font-montserrat)' }}>
                    Profession
                  </label>
                  <input
                    type="text"
                    className="w-full p-2 border rounded-lg"
                    style={{ fontFamily: 'var(--font-montserrat)' }}
                    placeholder="e.g., Software Engineer"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-black mb-1" style={{ fontFamily: 'var(--font-montserrat)' }}>
                    Company Name
                  </label>
                  <input
                    type="text"
                    className="w-full p-2 border rounded-lg"
                    style={{ fontFamily: 'var(--font-montserrat)' }}
                    placeholder="e.g., Tech Innovations Inc."
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-black mb-1" style={{ fontFamily: 'var(--font-montserrat)' }}>
                    LinkedIn Profile
                  </label>
                  <input
                    type="url"
                    className="w-full p-2 border rounded-lg"
                    style={{ fontFamily: 'var(--font-montserrat)' }}
                    placeholder="e.g., https://www.linkedin.com/in/yourprofile"
                  />
                </div>

                <div>
                  <label className="block text-xl font-medium text-black mb-4" style={{ fontFamily: 'var(--font-montserrat)' }}>
                    Looking For
                  </label>
                  <div className="space-y-3 bg-[#F9F6F4] p-4 rounded-lg">
                    {preferences.map(preference => (
                      <div key={preference.id} className="bg-white p-3 rounded-lg shadow-sm">
                        {editingId === preference.id ? (
                          <div className="w-full">
                            <input
                              type="text"
                              value={newPreference}
                              onChange={(e) => setNewPreference(e.target.value)}
                              className="w-full p-2 border rounded-lg"
                              style={{ fontFamily: 'var(--font-montserrat)' }}
                            />
                            <div className="flex items-center justify-between mt-3">
                              <div className="flex gap-6">
                                <label className="flex items-center gap-2">
                                  <input
                                    type="radio"
                                    name={`edit-preference-type-${preference.id}`}
                                    value="must-have"
                                    checked={editingPreference?.type === 'must-have'}
                                    onChange={() => setEditingPreference({ id: preference.id, type: 'must-have' })}
                                  />
                                  <span>Must Have</span>
                                </label>
                                <label className="flex items-center gap-2">
                                  <input
                                    type="radio"
                                    name={`edit-preference-type-${preference.id}`}
                                    value="nice-to-have"
                                    checked={editingPreference?.type === 'nice-to-have'}
                                    onChange={() => setEditingPreference({ id: preference.id, type: 'nice-to-have' })}
                                  />
                                  <span>Nice to Have</span>
                                </label>
                              </div>
                              <button
                                className="px-4 py-1 bg-black text-white rounded-lg hover:bg-gray-800"
                                onClick={() => handleUpdatePreference(preference.id)}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="flex items-center justify-between">
                            <span>{preference.text}</span>
                            <div className="flex items-center gap-2">
                              <span className="text-xs bg-[#F9F6F4] px-2 py-1 rounded">
                                {preference.isMustHave ? 'Must Have' : 'Nice to Have'}
                              </span>
                              <button
                                className="p-1 text-[#8A7B6C] hover:text-[#635A4F]"
                                onClick={() => handleEditClick(preference)}
                              >
                                <PencilIcon className="w-4 h-4" />
                              </button>
                              <button
                                className="p-1 text-[#8A7B6C] hover:text-red-500"
                                onClick={() => handleDeletePreference(preference.id)}
                              >
                                <XMarkIcon className="w-4 h-4" />
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}

                    <div className="bg-white p-3 rounded-lg shadow-sm">
                      <input
                        type="text"
                        className="w-full p-2 border rounded-lg"
                        style={{ fontFamily: 'var(--font-montserrat)' }}
                        placeholder="Add new preference"
                        value={newPreference}
                        onChange={(e) => setNewPreference(e.target.value)}
                      />
                      <div className="flex items-center justify-between mt-3">
                        <div className="flex gap-6">
                          <label className="flex items-center gap-2">
                            <input
                              type="radio"
                              name="new-preference-type"
                              value="must-have"
                              checked={preferenceType === 'must-have'}
                              onChange={(e) => setPreferenceType(e.target.value as 'must-have')}
                            />
                            <span>Must Have</span>
                          </label>
                          <label className="flex items-center gap-2">
                            <input
                              type="radio"
                              name="new-preference-type"
                              value="nice-to-have"
                              checked={preferenceType === 'nice-to-have'}
                              onChange={(e) => setPreferenceType(e.target.value as 'nice-to-have')}
                            />
                            <span>Nice to Have</span>
                          </label>
                        </div>
                        <button
                          className="px-4 py-1 bg-[#9F8E7D] text-white rounded-lg hover:bg-[#8A7B6C]"
                          onClick={handleAddPreference}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-8">
                  <label className="block text-xl font-medium text-black mb-2" style={{ fontFamily: 'var(--font-montserrat)' }}>
                    Membership Status
                  </label>
                  <div className="space-y-2">
                    <div className="text-sm text-[#766A5D]">Current Tier: Premium</div>
                    <button className="px-4 py-2 bg-[#F9F6F4] text-[#635A4F] rounded border border-[#C5C0B8] hover:-translate-y-0.5 transition-transform duration-200 hover:bg-[#E8E6E4]">
                      Upgrade Membership
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        ) : (
          <div className="bg-white rounded-lg shadow p-6">
            <section className="mb-8">
              <h2 className="text-lg font-medium mb-4" style={{ fontFamily: 'var(--font-montserrat)' }}>Photos</h2>
              <div className="grid grid-cols-3 gap-4">
                {photos.map(photo => (
                  photo.url ? (
                    <div key={photo.id} className="relative aspect-[4/3] bg-gray-100 rounded-lg flex items-center justify-center overflow-hidden">
                      <img
                        src={photo.url}
                        alt="Profile"
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute top-2 right-2">
                        <button
                          className="p-0.5 sm:p-1 bg-red-500 rounded-full text-white"
                          onClick={() => handleDeletePhoto(photo.id)}
                        >
                          <XMarkIcon className="w-3 h-3 sm:w-4 sm:h-4" />
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div
                      key={photo.id}
                      className="aspect-[4/3] bg-[#F9F6F4] rounded-lg border-2 border-dashed border-[#C5C0B8] flex items-center justify-center cursor-pointer hover:bg-[#E8E6E4]"
                      onClick={() => document.getElementById('imageUpload')?.click()}
                    >
                      <input
                        type="file"
                        id="imageUpload"
                        className="hidden"
                        accept="image/*"
                        onChange={(e) => {
                          if (e.target.files && e.target.files[0]) {
                            const file = e.target.files[0];
                            // Handle the file upload here
                            e.target.value = '';
                          }
                        }}
                      />
                      <PlusIcon className="w-8 h-8 text-[#9F8E7D]" />
                    </div>
                  )
                ))}
              </div>
            </section>

            <section className="mb-8">
              <h2 className="text-lg font-medium mb-4" style={{ fontFamily: 'var(--font-montserrat)' }}>Bio</h2>
              <div className="relative">
                <textarea
                  className="w-full p-3 border rounded-lg resize-none pr-10 focus:border-[#9F8E7D] focus:ring-[#9F8E7D] focus:ring-1 focus:outline-none"
                  style={{ fontFamily: 'var(--font-montserrat)' }}
                  rows={4}
                  maxLength={225}
                  placeholder="Tell us about yourself..."
                />
                <div className="absolute bottom-3 right-3">
                  <button className="p-1 text-[#9F8E7D] hover:text-[#635A4F] bg-white">
                    <PencilIcon className="w-4 h-4" />
                  </button>
                </div>
                <div className="text-sm text-[#8A7B6C] mt-1" style={{ fontFamily: 'var(--font-montserrat)' }}>
                  Character limit: 225
                </div>
              </div>
            </section>

            <section>
              <h2 className="text-lg font-medium mb-4" style={{ fontFamily: 'var(--font-montserrat)' }}>Personal Information</h2>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-black mb-1" style={{ fontFamily: 'var(--font-montserrat)' }}>
                    Email
                  </label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full p-2 border rounded-lg focus:border-[#9F8E7D] focus:ring-[#9F8E7D] focus:ring-1 focus:outline-none"
                    placeholder="your@email.com"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-black mb-1" style={{ fontFamily: 'var(--font-montserrat)' }}>
                    Date of Birth
                  </label>
                  <div className="grid grid-cols-3 gap-4">
                    <select className="w-full p-2 border rounded-lg [&>option]:bg-[#D8D4CC] focus:border-[#9F8E7D] focus:ring-[#9F8E7D] focus:ring-1 focus:outline-none"
                      style={{ fontFamily: 'var(--font-montserrat)' }}>
                      <option value="" disabled selected>Month</option>
                      {[
                        "January", "February", "March", "April", "May", "June",
                        "July", "August", "September", "October", "November", "December"
                      ].map((month, index) => (
                        <option key={month} value={index + 1}>
                          {month}
                        </option>
                      ))}
                    </select>
                    <select className="w-full p-2 border rounded-lg [&>option]:bg-[#D8D4CC] focus:border-[#9F8E7D] focus:ring-[#9F8E7D] focus:ring-1 focus:outline-none" style={{ fontFamily: 'var(--font-montserrat)' }}>
                      <option value="" disabled selected>Day</option>
                      {[...Array(31)].map((_, i) => (
                        <option key={i + 1} value={i + 1}>
                          {i + 1}
                        </option>
                      ))}
                    </select>
                    <select className="w-full p-2 border rounded-lg [&>option]:bg-[#D8D4CC] focus:border-[#9F8E7D] focus:ring-[#9F8E7D] focus:ring-1 focus:outline-none" style={{ fontFamily: 'var(--font-montserrat)' }}>
                      <option value="" disabled selected>Year</option>
                      {[...Array((new Date()).getFullYear() - 1939)].map((_, i) => {
                        const year = (new Date()).getFullYear() - i;
                        return (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-black mb-1" style={{ fontFamily: 'var(--font-montserrat)' }}>
                    Gender
                  </label>
                  <select className="w-full p-2 border rounded-lg [&>option]:bg-[#D8D4CC] focus:border-[#9F8E7D] focus:ring-[#9F8E7D] focus:ring-1 focus:outline-none" style={{ fontFamily: 'var(--font-montserrat)' }}>
                    <option value="" disabled selected>Select gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="non-binary">Non-binary</option>
                    <option value="other">Other</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-black mb-1" style={{ fontFamily: 'var(--font-montserrat)' }}>
                    Attracted To
                  </label>
                  <select 
                    value={attractedTo}
                    onChange={(e) => setAttractedTo(e.target.value)}
                    className="w-full p-2 border rounded-lg [&>option]:bg-[#D8D4CC] focus:border-[#9F8E7D] focus:ring-[#9F8E7D] focus:ring-1 focus:outline-none" 
                    style={{ fontFamily: 'var(--font-montserrat)' }}
                  >
                    <option value="" disabled>Select gender(s) you're attracted to</option>
                    <option value="men">Men</option>
                    <option value="women">Women</option>
                    <option value="non-binary">Non-binary</option>
                    <option value="all">All Genders</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-black mb-1" style={{ fontFamily: 'var(--font-montserrat)' }}>
                    Religion
                  </label>
                  <select className="w-full p-2 border rounded-lg [&>option]:bg-[#D8D4CC] focus:border-[#9F8E7D] focus:ring-[#9F8E7D] focus:ring-1 focus:outline-none" style={{ fontFamily: 'var(--font-montserrat)' }}>
                    <option value="" disabled selected>Select your religion</option>
                    <option value="christianity">Christianity</option>
                    <option value="islam">Islam</option>
                    <option value="hinduism">Hinduism</option>
                    <option value="buddhism">Buddhism</option>
                    <option value="judaism">Judaism</option>
                    <option value="sikhism">Sikhism</option>
                    <option value="atheism">Atheism</option>
                    <option value="agnosticism">Agnosticism</option>
                    <option value="other">Other</option>
                    <option value="prefer-not-to-say">Prefer not to say</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-black mb-1" style={{ fontFamily: 'var(--font-montserrat)' }}>
                    Current Location
                  </label>
                  <input
                    type="text"
                    className="w-full p-2 border rounded-lg focus:border-[#9F8E7D] focus:ring-[#9F8E7D] focus:ring-1 focus:outline-none"
                    style={{ fontFamily: 'var(--font-montserrat)' }}
                    placeholder="e.g., New York, NY"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-black mb-1" style={{ fontFamily: 'var(--font-montserrat)' }}>
                    Hometown
                  </label>
                  <input
                    type="text"
                    className="w-full p-2 border rounded-lg"
                    style={{ fontFamily: 'var(--font-montserrat)' }}
                    placeholder="e.g., Chicago, IL"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-black mb-1" style={{ fontFamily: 'var(--font-montserrat)' }}>
                    Profession
                  </label>
                  <input
                    type="text"
                    className="w-full p-2 border rounded-lg"
                    style={{ fontFamily: 'var(--font-montserrat)' }}
                    placeholder="e.g., Software Engineer"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-black mb-1" style={{ fontFamily: 'var(--font-montserrat)' }}>
                    Company Name
                  </label>
                  <input
                    type="text"
                    className="w-full p-2 border rounded-lg"
                    style={{ fontFamily: 'var(--font-montserrat)' }}
                    placeholder="e.g., Tech Innovations Inc."
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-black mb-1" style={{ fontFamily: 'var(--font-montserrat)' }}>
                    LinkedIn Profile
                  </label>
                  <input
                    type="url"
                    className="w-full p-2 border rounded-lg"
                    style={{ fontFamily: 'var(--font-montserrat)' }}
                    placeholder="e.g., https://www.linkedin.com/in/yourprofile"
                  />
                </div>

                <div>
                  <label className="block text-xl font-medium text-black mb-4" style={{ fontFamily: 'var(--font-montserrat)' }}>
                    Looking For
                  </label>
                  <div className="space-y-3 bg-[#F9F6F4] p-4 rounded-lg">
                    {preferences.map(preference => (
                      <div key={preference.id} className="bg-white p-3 rounded-lg shadow-sm">
                        {editingId === preference.id ? (
                          <div className="w-full">
                            <input
                              type="text"
                              value={newPreference}
                              onChange={(e) => setNewPreference(e.target.value)}
                              className="w-full p-2 border rounded-lg"
                              style={{ fontFamily: 'var(--font-montserrat)' }}
                            />
                            <div className="flex items-center justify-between mt-3">
                              <div className="flex gap-6">
                                <label className="flex items-center gap-2">
                                  <input
                                    type="radio"
                                    name={`edit-preference-type-${preference.id}`}
                                    value="must-have"
                                    checked={editingPreference?.type === 'must-have'}
                                    onChange={() => setEditingPreference({ id: preference.id, type: 'must-have' })}
                                  />
                                  <span>Must Have</span>
                                </label>
                                <label className="flex items-center gap-2">
                                  <input
                                    type="radio"
                                    name={`edit-preference-type-${preference.id}`}
                                    value="nice-to-have"
                                    checked={editingPreference?.type === 'nice-to-have'}
                                    onChange={() => setEditingPreference({ id: preference.id, type: 'nice-to-have' })}
                                  />
                                  <span>Nice to Have</span>
                                </label>
                              </div>
                              <button
                                className="px-4 py-1 bg-black text-white rounded-lg hover:bg-gray-800"
                                onClick={() => handleUpdatePreference(preference.id)}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="flex items-center justify-between">
                            <span>{preference.text}</span>
                            <div className="flex items-center gap-2">
                              <span className="text-xs bg-[#F9F6F4] px-2 py-1 rounded">
                                {preference.isMustHave ? 'Must Have' : 'Nice to Have'}
                              </span>
                              <button
                                className="p-1 text-[#8A7B6C] hover:text-[#635A4F]"
                                onClick={() => handleEditClick(preference)}
                              >
                                <PencilIcon className="w-4 h-4" />
                              </button>
                              <button
                                className="p-1 text-[#8A7B6C] hover:text-red-500"
                                onClick={() => handleDeletePreference(preference.id)}
                              >
                                <XMarkIcon className="w-4 h-4" />
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}

                    <div className="bg-white p-3 rounded-lg shadow-sm">
                      <input
                        type="text"
                        className="w-full p-2 border rounded-lg"
                        style={{ fontFamily: 'var(--font-montserrat)' }}
                        placeholder="Add new preference"
                        value={newPreference}
                        onChange={(e) => setNewPreference(e.target.value)}
                      />
                      <div className="flex items-center justify-between mt-3">
                        <div className="flex gap-6">
                          <label className="flex items-center gap-2">
                            <input
                              type="radio"
                              name="new-preference-type"
                              value="must-have"
                              checked={preferenceType === 'must-have'}
                              onChange={(e) => setPreferenceType(e.target.value as 'must-have')}
                            />
                            <span>Must Have</span>
                          </label>
                          <label className="flex items-center gap-2">
                            <input
                              type="radio"
                              name="new-preference-type"
                              value="nice-to-have"
                              checked={preferenceType === 'nice-to-have'}
                              onChange={(e) => setPreferenceType(e.target.value as 'nice-to-have')}
                            />
                            <span>Nice to Have</span>
                          </label>
                        </div>
                        <button
                          className="px-4 py-1 bg-[#9F8E7D] text-white rounded-lg hover:bg-[#8A7B6C]"
                          onClick={handleAddPreference}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-8">
                  <label className="block text-xl font-medium text-black mb-2" style={{ fontFamily: 'var(--font-montserrat)' }}>
                    Membership Status
                  </label>
                  <div className="space-y-2">
                    <div className="text-sm text-[#766A5D]">Current Tier: Premium</div>
                    <button className="px-4 py-2 bg-[#F9F6F4] text-[#635A4F] rounded border border-[#C5C0B8] hover:-translate-y-0.5 transition-transform duration-200 hover:bg-[#E8E6E4]">
                      Upgrade Membership
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </div>

      <div
        className="fixed bottom-0 left-0 right-0 px-4 pb-6 pt-8 bg-gradient-to-t from-[#F9F6F4] via-[#F9F6F4]/80 to-transparent"
        style={{ fontFamily: 'Montserrat' }}
      >
        <div className="max-w-xl mx-auto">
          <button
            onClick={handleSaveProfile}
            className="w-full h-14 bg-black text-white font-medium rounded-lg hover:bg-gray-900 transition-all duration-300"
            style={{ fontFamily: 'Montserrat' }}
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

const ProfileSection: FC<{ title: string; children: React.ReactNode }> = ({
  title,
  children,
}) => (
  <div className="w-full">
    <h2 className="text-lg font-bold font-lato mb-2">{title}</h2>
    <div className="bg-white rounded-lg shadow-md p-4">{children}</div>
  </div>
);

const InfoItem: FC<{ title: string; children: React.ReactNode }> = ({
  title,
  children,
}) => (
  <div className="mb-2">
    <h3 className="text-md font-bold font-lato">{title}</h3>
    <p className="text-sm font-lato">{children}</p>
  </div>
);

export default ClientProfilePage;
