import React, { FC, useState, useEffect } from "react";
import Div100vh from "react-div-100vh";
import { useMatches } from "../../hooks/useMatches";
import { usePostHog } from "posthog-js/react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Match } from "../../core/models";
import { Header } from "../../components/Header";

// Placeholder for loading state

const LoadingSkeleton: FC = () => (
  <Div100vh className="bg-[#f4f1ec] flex flex-col items-center justify-center !font-['Montserrat']">
    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-gray-700"></div>
    <p className="mt-4 text-lg font-light text-gray-600">Finding your match...</p>
  </Div100vh>
);

// Add this new component at the top of the file, after imports
const PhoneModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
}> = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-40 flex items-end">
      <div className="fixed inset-0 bg-black opacity-50" onClick={onClose}></div>
      <div className="relative z-50 w-[90%] bg-white rounded-lg shadow-lg mx-auto mb-24">
        <div className="px-6 pt-4">
          <h3 className="text-md font-medium">{title}</h3>
        </div>
        {children}
      </div>
    </div>
  );
};

const IntroductionRequestPage: FC = () => {
  const posthog = usePostHog();
  const [searchParams] = useSearchParams();
  const matchId = searchParams.get("matchId");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [isBottomBarTransparent, setIsBottomBarTransparent] = useState(false);
  const [introductionStatus, setIntroductionStatus] = useState<string | null>(null);
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  const {
    matches,
    targetGender,
    isLoading,
    sessionId,
    requestIntroduction,
    requestIntroError,
    getIntroductionStatus,
  } = useMatches();

  const currentMatch = matches[0];

  const placeholderMatch: Match = {
    id: "match_123",
    name: "Sarah J.",
    age: 28,
    location: "San Francisco, CA",
    bio: "Love hiking, photography, and trying new restaurants",
    description: "Sarah is an outgoing software engineer who enjoys outdoor activities",
    fit_description: "You both share a passion for hiking and technology",
    prospect_image: "https://images.squarespace-cdn.com/content/v1/616754c4a06ef45b9f50e0db/1635103628149-KWX6DSQ8FB6I8SP6XF0F/Vanessa+girl+with+the+dogs-min.jpg",
    intro_tracking_link: "https://app.example.com/intros/match_123",
    personalized_matchmaker_note: "Sarah is a great match for you because she shares your passion for hiking and technology",
    client_is_paying: true,
  };

  const handleRequestIntroduction = async () => {
    if (!matchId) {
      console.error("No match ID available in URL");
      return;
    }

    setIsModalOpen(true);
    const success = await requestIntroduction(matchId);
    if (success) {
      posthog.capture("introduction_requested", {
        match_id: matchId,
      });
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    navigate(`/introductions?sessionId=${sessionId}`);
  };

  // Add toggle handler
  const toggleBottomBarTransparency = () => {
    setIsBottomBarTransparent(prev => !prev);
  };

  // Add useEffect to load Google Fonts
  React.useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap';
    document.head.appendChild(link);
  }, []);

  // Add this useEffect to check introduction status
  useEffect(() => {
    const checkIntroductionStatus = async () => {
      if (matchId) {
        try {
          const response = await getIntroductionStatus(matchId);
          if (response.exists) {
            setIntroductionStatus(response.status);
          }
        } catch (error) {
          console.error("Error checking introduction status:", error);
        }
      }
    };

    checkIntroductionStatus();
  }, [matchId, getIntroductionStatus]);

  const handlePhoneSubmit = (phoneNumber: string) => {
    if (!phoneNumber.trim()) {
      return;
    }
    setIsPhoneModalOpen(false);
    handleRequestIntroduction();
    navigate('/introduction-success');
  };

  if (isLoading) return <LoadingSkeleton />;

  return (
    <div className="min-h-screen bg-[#F9F6F4]">
      <Header sessionId={sessionId} />

      <main className="mx-auto px-4 py-8 bg-[#E8E6E1]">
        <div className="max-w-2xl bg-white rounded-lg shadow-lg mx-auto md:px-8 pt-4 pb-[6.5rem]" style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}>
          <div className="bg-white rounded-lg shadow-sm px-8 py-4 relative">
            <h2 style={{ fontFamily: 'Montserrat' }} className="md:text-2xl text-xl font-semibold text-center mb-4">
              Meet {currentMatch ? currentMatch.name : placeholderMatch.name} — {targetGender === 'male' ? 'he' : 'she'} might be the one!
            </h2>
            <div className="max-w-xl mx-auto">

              {/* Image */}
              <div className="aspect-[3/4] bg-gray-200 mb-6 rounded-lg overflow-hidden max-w-sm mx-auto shadow-md">
                <img
                  src={currentMatch ? `data:image/jpeg;base64,${currentMatch.prospect_image}` : placeholderMatch.prospect_image}
                  alt={currentMatch ? currentMatch.name : placeholderMatch.name}
                  className="w-full h-full object-cover"
                  onError={(e) => (e.currentTarget.src = "/fallback-image.jpg")}
                />
              </div>

              {/* Match Details */}
              <div className="text-center">
                <p style={{ fontFamily: 'Montserrat' }} className="text-lg font-light text-gray-600 mb-3">
                  {currentMatch ? `${currentMatch.location} | ${currentMatch.age} years old` : `${placeholderMatch.location} | ${placeholderMatch.age} years old`}
                </p>
                <h4 style={{ fontFamily: 'Montserrat' }} className="text-lg md:text-xl font-semibold mb-2">
                  Bio
                </h4>
                <p style={{ fontFamily: 'Montserrat' }} className="text-md md:text-md text-gray-600 mb-6 font-light">
                  {currentMatch ? currentMatch.bio : placeholderMatch.bio}
                </p>
              </div>

              {currentMatch && currentMatch.personalized_matchmaker_note && (
                <div>
                  <h4 style={{ fontFamily: 'Montserrat' }} className="font-serif text-lg mb-2 font-semibold">Why we selected this match</h4>
                  <p style={{ fontFamily: 'Montserrat' }} className="text-sm md:text-md text-gray-600 mb-6 font-light">
                    {currentMatch.personalized_matchmaker_note}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Action Buttons */}
        <div
          onClick={toggleBottomBarTransparency}
          className={`fixed bottom-0 left-0 right-0 px-4 pb-6 pt-8 transition-all duration-300 z-50 ${
            isBottomBarTransparent
              ? 'bg-transparent'
              : 'bg-gradient-to-t from-[#F9F6F4] via-[#F9F6F4]/80 to-transparent'
          }`}
          style={{ fontFamily: 'Montserrat' }}
        >
          <div className="max-w-xl mx-auto space-y-3">
            <button
              onClick={(e) => {
                e.stopPropagation();
                if (isPhoneModalOpen) {
                  setIsPhoneModalOpen(false);
                  navigate('/introduction-success');
                } else {
                  setIsPhoneModalOpen(true);
                }
              }}
              className={`w-full h-14 bg-black text-white font-medium rounded-lg hover:bg-gray-900 transition-all duration-300 ${
                isBottomBarTransparent && !isPhoneModalOpen ? 'opacity-0 pointer-events-none' : 'opacity-100'
              }`}
              style={{ fontFamily: 'Montserrat' }}
            >
              {isPhoneModalOpen ? 'Submit' : 'Get Introduced'}
            </button>
          </div>
        </div>
      </main>

      {/* Replace Modal with PhoneModal */}
      {isPhoneModalOpen && (
        <PhoneModal 
          isOpen={isPhoneModalOpen} 
          onClose={() => setIsPhoneModalOpen(false)} 
          title="Enter your phone number"
        >
          <div className="py-2 px-6 bg-white rounded-lg">
            <input
              type="tel"
              placeholder="(555) 123-4567"
              className="w-full p-2 border-b border-gray-300 mb-4 focus:outline-none focus:border-gray-700"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <label className="flex items-center gap-2 mb-4">
              <input type="checkbox" className="rounded" defaultChecked />
              <span className="text-xs">Sign me up to receive even more Curated matches!</span>
            </label>
          </div>
        </PhoneModal>
      )}
    </div>
  )
};

export default IntroductionRequestPage;