import React from "react";
import { AiOutlineClose } from "react-icons/ai"; // Importing close icon from react-icons

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  onConfirmText?: string;
  onConfirm?: () => void;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onConfirmText = "Exit",
  onConfirm,
  onClose,
  title,
  children,
}) => {
  return (
    <div
      className={`${isOpen ? "fixed" : "hidden"} z-10 inset-0 overflow-y-auto`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all absolute md:static top-32 md:top-0 mx-2   md:my-8 md:align-middle md:max-w-lg md:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start ">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                <div className="flex justify-between items-center ">
                  <h3
                    className="text-lg leading-6 font-medium text-gray-900  text-center w-full"
                    id="modal-title"
                  >
                    {title}
                  </h3>
                  <button
                    onClick={onClose}
                    className="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                  >
                    <AiOutlineClose className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-2">{children}</div>
              </div>
            </div>
          </div>
          {onConfirm && (
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className="w-full h-14 bg-black text-white font-medium rounded-lg hover:bg-gray-900 transition-all duration-300"
                onClick={onConfirm}
              >
                {onConfirmText}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;