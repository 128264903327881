import React, { FC, useEffect } from "react";
import { useMatches } from "../../hooks/useMatches";
import { Header } from "../../components/Header";
import { Link, useSearchParams } from "react-router-dom";

const introductionStatusToLabel = (status: string) => {
  switch (status) {
    case "pending":
      return "Pending";
    case "in progress":
      return "Reached Out";
    case "in progress (followed up)":
      return "Followed Up";
    case "accepted":
      return "Accepted";
    case "rejected":
      return "Rejected";
    case "stale":
      return "Rejected";
  }
};

const Introductions: FC = () => {
  const { introductions, introductionsLoading, fetchIntroductions } =
    useMatches();

  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("sessionId");

  useEffect(() => {
    fetchIntroductions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="min-h-screen bg-[#F9F6F4]">
      <Header sessionId={sessionId} />
      <main className="max-w-4xl mx-auto">
        <h1 className="text-xl md:text-2xl font-light mb-4 md:mb-8 mt-8 text-black tracking-wide" style={{ fontFamily: 'var(--font-montserrat)' }}>
          Pending Introductions
        </h1>
        <div className="bg-white rounded-lg overflow-hidden coastal-shadow p-6 md:p-8 border border-taupe/20">
          <ul className="divide-y divide-taupe/20">
          {introductionsLoading ? (
            <div className="text-gray-500 text-center items-center h-[500px] flex flex-col justify-center">
              Loading introductions...
            </div>
          ) : introductions.length === 0 ? (
            <div className="text-gray-500 text-center items-center h-[500px] flex flex-col justify-center ">
              No introductions yet. <br /> Ask your matchmaker for an
              introduction
            </div>
            ) : (
              introductions.map((intro) => (
                <li key={intro.id} className="py-6 first:pt-0 last:pb-0">
                  <div className="flex items-center">
                  <img
                      src={`data:image/jpeg;base64,${intro.prospect_base64_image}`}
                      alt={intro.prospect_name}
                      width={80}
                      height={80}
                      className="rounded-lg mr-6 object-cover"
                    />
                  <div className="flex-grow">
                    <p className="text-lg font-light tracking-wide text-black mb-2" style={{ fontFamily: 'var(--font-montserrat)' }}>
                      {intro.prospect_name}, {intro.prospect_age}
                    </p>
                    <p className="text-taupe mb-3 tracking-wide text-xs uppercase" style={{ fontFamily: 'var(--font-montserrat)' }}>
                      Status: {introductionStatusToLabel(intro.status)}
                    </p>
                    <Link
                      to={`/match?matchId=${intro.match_id}`}
                      className="text-black hover:text-taupe transition-colors tracking-wide text-xs uppercase font-bold bg-[#F5F3F0] px-3 py-2 rounded-md"
                      style={{ fontFamily: 'var(--font-montserrat)' }}
                    >
                      View Profile
                    </Link>
                  </div>
                </div>
              </li>
            ))
          )}
          </ul>
        </div>
      </main>
    </div>
  );
};

export default Introductions;
