import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  getMatches,
  getMatch,
  getAnotherMatch,
  getIntroductions,
  requestIntroductionService,
  getIntroductionStatus,
} from "../services/matchesService";
import { Introduction, Match } from "../core/models";
import { usePostHog } from "posthog-js/react";

export const useMatches = () => {
  const posthog = usePostHog();
  const [searchParams] = useSearchParams();
  const [matches, setMatches] = useState<Match[]>([]);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [matchId, setMatchId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [requestAnotherMatchLoading, setRequestAnotherMatchLoading] =
    useState(false);
  const [requestAnotherMatchSuccess, setRequestAnotherMatchSuccess] = useState<
    boolean | null
  >(null);
  const [targetGender, setTargetGender] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [introductions, setIntroductions] = useState<Introduction[]>([]);
  const [introductionsLoading, setIntroductionsLoading] = useState(true);
  const [creditBalance, setCreditBalance] = useState<number | null>(null);
  const [billingCycleStartDay, setBillingCycleStartDay] = useState<
    number | null
  >(null);
  const [requestIntroError, setRequestIntroError] = useState<string | null>(
    null
  );

  useEffect(() => {
    const fetchMatches = async () => {
      const sessionId = searchParams.get("sessionId");
      const matchId = searchParams.get("matchId");
      if (!sessionId && !matchId) {
        setError("No session ID or match ID provided");
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        let data;
        if (matchId) {
          data = await getMatch(matchId);
        } else if (sessionId) {
          data = await getMatches(sessionId);
        } else {
          throw new Error("No session ID or match ID provided");
        }
        setMatches(data.matches);
        setCreditBalance(data.credit_balance);
        setTargetGender(data.target_gender);
        setSessionId(sessionId || data.matches?.[0]?.user_session_id);
        setMatchId(data.match_id);
        setBillingCycleStartDay(data.billing_cycle_start_day);
        posthog?.identify(data.posthog_user_id, {
          target_gender: data.target_gender,
        });
      } catch (err) {
        setError(err instanceof Error ? err.message : String(err));
      } finally {
        setIsLoading(false);
      }
    };

    fetchMatches();
  }, [searchParams]);

  const fetchIntroductions = async () => {
    const sessionId = searchParams.get("sessionId");
    if (!sessionId) {
      alert("No session ID available");
      return;
    }
    const introductions = await getIntroductions(sessionId);
    setIntroductions(introductions.map((intro) => intro as Introduction));
    setIntroductionsLoading(false);
  };

  const requestAnotherMatch = async (feedback: string) => {
    let id = searchParams.get("matchId");
    if (!id) {
      throw new Error("No match ID available");
    }
    setRequestAnotherMatchLoading(true);
    try {
      await getAnotherMatch(id, feedback);
      return true;
    } catch (error) {
      console.error("Error requesting another match:", error);
      return false;
    } finally {
      setRequestAnotherMatchLoading(false);
      setRequestAnotherMatchSuccess(true);
    }
  };

  const requestIntroduction = async (matchId: string) => {
    try {
      await requestIntroductionService(matchId);
      return true;
    } catch (error) {
      console.error("Error requesting introduction:", error);

      if (error instanceof Error) {
        setRequestIntroError(error.message);
      }
      return false;
    }
  };

  return {
    matches,
    creditBalance,
    billingCycleStartDay,
    introductions,
    isLoading,
    error,
    sessionId,
    matchId,
    targetGender,
    requestAnotherMatchSuccess,
    requestAnotherMatchLoading,
    requestAnotherMatch,
    introductionsLoading,
    fetchIntroductions,
    requestIntroduction,
    requestIntroError,
    getIntroductionStatus,
  };
};
