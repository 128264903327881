import { useEffect, useState } from "react";
import { getUserMembership } from "../services/userService";
import { useSearchParams } from "react-router-dom";
import { UserMembership } from "../types/user";

const useUserMembership = () => {
  const [userMembership, setUserMembership] = useState<UserMembership | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const fetchUserMembership = async () => {
      try {
        const sessionId = searchParams.get("sessionId");
        if (!sessionId) {
          throw new Error("No session ID available");
        }
        setIsLoading(true);
        const userMembership = await getUserMembership(sessionId);
        setUserMembership(userMembership);
      } catch (err) {
        setError(err instanceof Error ? err.message : String(err));
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserMembership();
  }, [searchParams]);

  return { userMembership, isLoading, error };
};

export default useUserMembership;
